<template>
  <b-card
    v-if="!activityStatus.operative"
    class="mt-3 border-0"
    body-class="p-2 bg-gray-200 text-center"
  >
    <b-button class="m-auto pointer-events-none" variant="warning" v-if="notValid">
      Verificare l'account per poter proseguire
    </b-button>
    <b-button class="m-auto pointer-events-none" variant="warning" v-else-if="activityStatus.waitApproval">
      Informazioni in fase di verifica da parte dei nostri operatori
    </b-button>
    <b-button class="m-auto pointer-events-none" variant="warning" v-else-if="activityStatus.notOperative">
      Operatività sospesa. Contattare i nostri operatori per maggiori informazioni
    </b-button>
    <b-button v-else :disabled="isNotCompleteInfo" variant="success" class="m-auto"
              v-on:click="submitValidation">RICHIEDI CONTROLLO INFORMAZIONI INSERITE</b-button>
  </b-card>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';

export default {
  name: 'ValidationAction',
  props: {
    submitValidation: Function,
    isNotCompleteInfo: Boolean,
    notValid: Boolean,
  },
  computed: {
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
  },
};
</script>

<style scoped>

</style>
